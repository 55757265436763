import * as React from "react";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/header-one";
import Footer from "@layout/footer/footer-one";
import Banner from "@components/banners/Ai";
import CodeArea from '@containers/code-area'

const OpenCodePage = ({ pageContext, location }) => (
	<Layout location={location}>
		<Seo title="Open Code" />
		<Header />
		<Banner
			pageContext={pageContext}
			location={location}
			title="Open Code"
		/>
		<main className="site-wrapper-reveal">
			<CodeArea />
		</main>
		<Footer />
	</Layout>
)

export default OpenCodePage